<template>
  <div v-if="items">
    <div class="container-fluid card p-4 mt-1">
      <h4>Order # {{ items.order_id }}</h4>
      <hr />
      <div v-for="item in items.products" :key="item.id">
        <h4>
          Product: <b>{{ item.name }}</b> 
          <span style="margin-left: 10px;">{{$store.state.auth.currency}}{{item.amount}} X {{item.quantity}}</span>
        </h4>
        <h5>
        </h5>
        <br />
        <div v-if="JSON.parse(item.toppings).length">
          <h6>Toppings</h6>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Per Item Quantity</th>
                <th scope="col">Price</th>
                <th scope="col">Sub Total</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="product in JSON.parse(item.toppings)"
                :key="product.id"
              >
                <td>{{ product.names }}</td>
                <td>{{ product.counter }}</td>
                <td>{{$store.state.auth.currency}}{{ parseFloat(product.topp_price).toFixed(2) }}</td>
                <td>{{$store.state.auth.currency}}{{ parseFloat(product.topp_price*product.counter).toFixed(2) }}</td>
                <td>{{$store.state.auth.currency}}{{ parseFloat(product.topp_price*product.counter).toFixed(2) }} X {{ parseFloat(item.quantity).toFixed(2) }} = {{$store.state.auth.currency}}{{ parseFloat((product.topp_price*product.counter)*item.quantity).toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="JSON.parse(item.properties).length">
          <h6>Properties</h6>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Per Item Quantity</th>
                <th scope="col">Price</th>
                <th scope="col">Sub Total</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="prop in JSON.parse(item.properties)" :key="prop.id">
                <td>{{ prop.name }}</td>
                <td>{{ prop.counter }}</td>
                <td>{{$store.state.auth.currency}}{{ parseFloat(prop.price).toFixed(2) }}</td>
                <td>{{$store.state.auth.currency}}{{ parseFloat(prop.price*prop.counter).toFixed(2) }}</td>
                <td>{{$store.state.auth.currency}}{{ parseFloat(prop.price*prop.counter).toFixed(2) }} X {{ parseFloat(item.quantity).toFixed(2) }} = {{$store.state.auth.currency}}{{ parseFloat((prop.price*prop.counter)*item.quantity).toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h4 class="float-right mr-2">
            Product Total : {{$store.state.auth.currency}}{{ parseFloat(item.amount).toFixed(2) }} x
            {{ item.quantity }}
          </h4>
        </div>
      </div>
      <div>
        <h5 class="float-left">Customer Notes : {{ items.note }}</h5>
      </div>
      <div>
        <h5 class="float-right">Sub Total : {{$store.state.auth.currency}}{{ items.total }}</h5>
      </div>
      <hr />
      <div class="mt-3">
        <h5 class="float-right">Delivery : {{$store.state.auth.currency}}{{ parseFloat(items.delivery).toFixed(2) }}</h5>
      </div>
      <div class="mt-3">
        <h5 class="float-right">Discount : {{$store.state.auth.currency}}{{ parseFloat(items.discount).toFixed(2) }}</h5>
      </div>
      <div class="mt-3">
        <h5 class="float-right">Service Charges : {{$store.state.auth.currency}}{{ parseFloat(items.service_charges).toFixed(2) }}</h5>
      </div>
      <div class="mt-3">
        <h5 class="float-right">
          Total : <b>{{$store.state.auth.currency}}{{ parseFloat(items.final_amount).toFixed(2) }}</b>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["items"],
  mounted() {
    if (!this.items) {
      this.$router.push({
        name: "today",
      });
    }
  },
};
</script>

<style scoped></style>
